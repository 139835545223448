.t {
  transform: none;
}
.frame-wrap {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  font-size: 0rem;
}
