.t {
  transform: none;
}
[popup] {
  z-index: 200;
  position: fixed;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  opacity: 0;
  transition: opacity 0.5s 0s;
}
[popup] .dim {
  position: fixed;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 120%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 50;
}
[popup] .holder {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  z-index: 51;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
}
[popup] .close {
  background-image: url('/img/common/btn-close-white.png');
  background-repeat: no-repeat;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  right: 1.4375rem;
  top: 1.125rem;
  left: auto;
  bottom: auto;
  background-size: contain;
  z-index: 1;
}
[popup] .panel {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
[popup].on {
  opacity: 1;
}
@media (min-width: 768px) {
  [popup] .holder {
    position: relative;
  }
  [popup] .close {
    width: 1.875rem;
    height: 1.875rem;
    right: 1.875rem;
    top: 1.875rem;
    left: auto;
    bottom: auto;
  }
}
@media (min-width: 1024px) {
  [popup] .close {
    width: 1.875rem;
    height: 1.875rem;
    right: 2.5rem;
    top: 2.5rem;
    left: auto;
    bottom: auto;
  }
}
